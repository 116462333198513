<script lang="ts" context="module">
  type TableOfContentItem = { caption: string; id: string };
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import Tooltip from "@ui/Tooltip.svelte";
  import { isRTL } from "sbelt/text";
  import { getShowContext } from "./showContext";

  export let showEmpty: boolean = false;
  const context = getShowContext();

  let expandTOC: boolean = true;

  let tocItems: TableOfContentItem[] = [];

  onMount(() => {
    const elements = [...document.querySelectorAll(".author-show [data-block-toc=true], .container-show [data-block-toc=true]")];
    tocItems = elements.map((element) => {
      const htmlElement = element as HTMLElement;
      return { caption: htmlElement.dataset.tocText!, id: htmlElement.dataset.blockId! };
    });
  });

  function scrollMe(linkId: string) {
    document.querySelector(`${linkId}`)?.scrollIntoView({ behavior: "smooth", block: "start" });
    // In order to change the URL without actually redirecting and reloading the page,
    // We use the history for that...
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
    window.history.pushState({ id: linkId }, "", `${document.location.href.split("#")[0]}${linkId === "html" ? "" : linkId}`);
  }
</script>

{#if tocItems.length > 0 || showEmpty}
  <div class="fixed hidden toc-container text-13" style="--top:{context && context.hideToolbar ? '2.65rem' : ''};">
    <div class="w-56 bg-white rounded shadow-lg bg-opacity-70 backdrop-blur-md backdrop-filter">
      <div class="flex items-center justify-between px-2.5 py-2 border-b border-white text-14 font-semibold">
        Table of Contents
        <span class="mr-1 -ml-1 leading-none">
          <a href={"#"} on:click|preventDefault|stopPropagation={() => scrollMe("html")} class=" material-icons notranslate text-18"
            >vertical_align_top
          </a>
          <Tooltip>Scroll to the top of the newsletter</Tooltip>
        </span>
      </div>
      {#if expandTOC}
        {#each tocItems as tocItem, index}
          {@const linkId = `#${tocItem.id}`}
          <a
            class="flex items-start px-2.5 py-2 text-14 hover:text-nxgray-400 text-nxgray-700 last:rounded-b"
            href={linkId}
            on:click|preventDefault={() => scrollMe(linkId)}
            ><span class="font-semibold text-13" dir={isRTL(tocItem.caption) ? "rtl" : "ltr"}>{tocItem.caption}</span></a>
        {:else}
          <div class="border-t px-2.5 py-2">Nothing to show</div>
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style>
  .toc-container {
    left: 1rem;
    margin-top: var(--top, 4rem);
  }

  :global(.show-new .toc-container) {
    left: 0;
  }

  @media (min-width: 1150px) {
    :global(.show-new .toc-container) {
      display: block;
    }
  }

  @media (min-width: 1280px) {
    :global(.show-new .toc-container) {
      display: block;
      left: auto;
      right: var(--right, calc(50vw + 400px));
    }
  }

  @media (min-width: 1536px) {
    :global(.show_author_new .toc-container) {
      display: block;
    }
    .toc-container {
      display: block;
      left: auto;
      right: var(--right, calc(50vw + 400px));
    }
  }
</style>
